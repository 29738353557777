import React, { useState, useEffect } from "react";
import Sidebar from "../adminComponents/sidebar/Sidebar";
import Navbar from "../adminComponents/navbar/Navbar";
import { useParams } from "react-router-dom";
import {
  fetchProductItem,
  getCategories,
  getSubCategories,
  updateProduct,
} from "../../actions/adminRequests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ViewProduct() {
  const { id } = useParams();
  const [editOrSave, setEditOrSave] = useState(false);
  const [productItem, setProductItem] = useState({});

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [categoryChange, setCategoryChange] = useState("");
  const [subCategoryChange, setSubCategoryChange] = useState("");
  const [productImageChange, setProductImageChange] = useState("");
  const [isImageChange, setIsImageChange] = useState(false);
  // Add the following function to handle category change
  const handleCategoryChange = (categoryId) => {
    setCategoryChange(categoryId);
    getSubCategories().then((res) => {
      const filterSubCategory = res.filter((subcategory) => {
        return subcategory.category === parseInt(categoryId);
      });

      setSubcategories(filterSubCategory);
    });
  };

  const productUpdate_success = () => {
    toast.success("Product Updated Successfully", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
    });
  };

  const productUpdate_fail = () => {
    toast.error("Something went wrong", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
    });
  };

  useEffect(() => {
    fetchProductItem(id).then((res) => {
      setProductItem(res);
      setProductImageChange(res.images);
    });
    getCategories().then((res) => {
      setCategories(res);
    });
    getSubCategories().then((res) => {
      // filter subcategories based on selected category

      const filterSubCategory = res.filter((subcategory) => {
        return subcategory.category === productItem.category_n;
      });
      setSubcategories(filterSubCategory);
    });
  }, [id, productItem.category_n]);

  const handleUpdate = (e) => {
    e.preventDefault();
    setEditOrSave(!editOrSave);
  };

  const handleSave = (e) => {
    e.preventDefault();
    // data should be in formData format
    const formData = new FormData();
    formData.append("id", productItem.id);
    if (isImageChange) {
      formData.append("images", productItem.images);
    }

    formData.append("Name", productItem.Name);
    formData.append("description", productItem.description);
    formData.append(
      "category_n",
      categoryChange ? categoryChange : productItem.category_n
    );
    formData.append(
      "sub_category_n",
      subCategoryChange ? subCategoryChange : productItem.subcategory_n
    );
    formData.append("original_price", productItem.original_price);
    formData.append("discounted_price", productItem.discounted_price);
    formData.append("quantity", productItem.quantity);
    formData.append("is_new", productItem.is_new);
    formData.append("is_hot", productItem.is_hot);

    updateProduct(formData)
      .then((res) => {
        productUpdate_success();
        setIsImageChange(false);
      })
      .catch((err) => {
        productUpdate_fail();
      });

    setEditOrSave(!editOrSave);
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const reader = new FileReader();
    setIsImageChange(true);
    reader.onload = function (event) {
      setProductImageChange(event.target.result);
    };

    if (file) {
      setProductItem({ ...productItem, images: file });
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="adminViewProducts">
      <Sidebar />
      <div className="adminListContainer">
        <Navbar />
        <div className="top">
          <h1>Product</h1>
        </div>
        <div className="bottom">
          <div className="left">
            {productImageChange && productImageChange !== null ? (
              // if editOrSave is true then show choose a file button or else show the image
              editOrSave ? (
                <>
                  <img
                    id="productImage"
                    src={productImageChange}
                    alt="productImage"
                  />
                  <input
                    type="file"
                    name="file"
                    id="file_up"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e)}
                  />
                  <label htmlFor="file_up"> Change image </label>
                </>
              ) : (
                <img src={productImageChange} alt="productImage" />
              )
            ) : (
              <img
                src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                alt="productImage"
              />
            )}
          </div>
          <div className="right">
            <form>
              <div className="formLeft">
                <label>Product Name</label>
                <input
                  type="text"
                  placeholder="Product Name"
                  value={productItem?.Name}
                  // if editOrSave is true then make the input field editable or else make it disabled
                  disabled={editOrSave ? false : true}
                  onChange={(e) =>
                    setProductItem({ ...productItem, Name: e.target.value })
                  }
                />
                <label>Product Description</label>
                <input
                  type="text"
                  placeholder="Product Description"
                  value={productItem?.description}
                  disabled={editOrSave ? false : true}
                  onChange={(e) =>
                    setProductItem({
                      ...productItem,
                      description: e.target.value,
                    })
                  }
                />
                <label>Quantity</label>
                <input
                  type="text"
                  placeholder="No. of items"
                  value={productItem?.quantity || 0}
                  disabled={editOrSave ? false : true}
                  onChange={(e) =>
                    setProductItem({
                      ...productItem,
                      quantity: e.target.value,
                    })
                  }
                />
                <label>Category</label>
                {/* if there is category data render them as option */}
                <select
                  name="category"
                  id="category"
                  defaultValue={productItem?.category?.id}
                  disabled={editOrSave ? false : true}
                  onChange={(e) => handleCategoryChange(e.target.value)}
                >
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.Name}
                    </option>
                  ))}
                </select>
                <label>Sub Category</label>
                {/* if there is any subcategory data render them as option */}
                <select
                  name="subcategory"
                  id="subcategory"
                  disabled={editOrSave ? false : true}
                  onChange={(e) => setSubCategoryChange(e.target.value)}
                  defaultValue={productItem?.subcategory?.id}
                >
                  {subcategories.map((subcategory) => (
                    <option key={subcategory.id} value={subcategory.id}>
                      {subcategory.Name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formRight">
                <div className="formRightTop">
                  <div className="formRightTopLeft">
                    <label>Original Price (in $)</label>
                    <input
                      type="text"
                      placeholder="$1.00"
                      value={productItem?.original_price}
                      disabled={editOrSave ? false : true}
                      onChange={(e) => {
                        setProductItem({
                          ...productItem,
                          original_price: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="formRightTopRight">
                    <label>Discounted Price (in $)</label>
                    <input
                      type="text"
                      placeholder="$0.99"
                      value={productItem?.discounted_price}
                      disabled={editOrSave ? false : true}
                      onChange={(e) =>
                        setProductItem({
                          ...productItem,
                          discounted_price: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="formRightBottom">
                    <div className="formRightBottomLeft">
                      <label>New Arrival</label>
                      <select
                        name="isNew"
                        id="isNew"
                        disabled={editOrSave ? false : true}
                        onChange={(e) =>
                          setProductItem({
                            ...productItem,
                            is_new: e.target.value,
                          })
                        }
                        defaultValue={productItem.is_new ? "true" : "false"}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                    <div className="formRightBottomRight">
                      <label>Hot Product</label>
                      <select
                        name="isHot"
                        id="isHot"
                        disabled={editOrSave ? false : true}
                        onChange={(e) =>
                          setProductItem({
                            ...productItem,
                            is_hot: e.target.value,
                          })
                        }
                        defaultValue={productItem.is_hot ? "true" : "false"}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>
                </div>

                {editOrSave ? (
                  <button
                    onClick={handleSave}
                    style={{ backgroundColor: "#7451f8" }}
                  >
                    Save
                  </button>
                ) : (
                  <button onClick={handleUpdate}>Edit </button>
                )}
              </div>
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewProduct;
