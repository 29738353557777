import axios from 'axios'

const baseurl = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
    baseURL: baseurl,
    headers: {
      "Content-Type": "application/json",
      'accept': 'application/json'
    },
  });
  
  axiosInstance.interceptors.request.use(
    (config) => {
      const access_token = localStorage.getItem('access_token');
      if (access_token) {
        config.headers["Authorization"] = `Bearer ${access_token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );


   axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
  
      if (err.response) {
        

        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          const refresh_token = localStorage.getItem("refresh_token");
  
          try {
            const rs = await axios.post(`${baseurl}/account/auth/token/`,{grant_type: 'refresh_token',
              refresh_token: refresh_token,  client_id: process.env.REACT_APP_CLIENT_ID,
              client_secret: process.env.REACT_APP_CLIENT_SECRET,
            });
    
      
  
            localStorage.setItem("access_token", rs.data.access_token);
            localStorage.setItem("refresh_token", rs.data.refresh_token);
    
        //     return;
        //   }
  
        //   try {
        //     const rs = await refreshToken();
        //     const { accessToken } = rs.data;
        //     window.localStorage.setItem("accessToken", accessToken);
        //     instance.defaults.headers.common["x-access-token"] = accessToken;
  
            return axiosInstance(originalConfig);
          } catch (_error) {
            if (_error.response && _error.response.data) {
              window.localStorage.removeItem("access_token");
              window.localStorage.removeItem("refresh_token");
              window.localStorage.removeItem("user_id");
              window.location.href = "/account/signin";
              return Promise.reject(_error.response.data);

            }
  
            return Promise.reject(_error);
          }
        }
  
        if (err.response.status === 403 && err.response.data) {
          window.location.href = "/account/signin";

          return Promise.reject(err.response.data);


        }


     
      }
      window.location.href = "/account/signin";

      return Promise.reject(err);
    }
  );
   
export default axiosInstance;



 
