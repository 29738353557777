import React, { useState, useEffect } from "react";
import Sidebar from "../adminComponents/sidebar/Sidebar";
import Navbar from "../adminComponents/navbar/Navbar";
import {
  addProduct,
  getCategories,
  getSubCategories,
} from "../../actions/adminRequests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
function NewProduct() {
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [isNew, setIsNew] = useState(false);
  const [isHot, setIsHot] = useState(false);
  const [image, setImage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const productAdd_success = () => {
    toast.success("Product Added Successful", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
    });
  };

  const productAdd_fail = () => {
    toast.error("Something went wrong", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
    });
  };
  const product_fill = () => {
    toast.warn("Please fill all details", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
    });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedImage(file);

      if (file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.onload = (e) => {
          setImage(e.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        console.error("Please select an image file.");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      productName !== "" &&
      description !== "" &&
      selectedCategory !== "" &&
      originalPrice !== "" &&
      discountedPrice !== "" &&
      selectedImage
    ) {
    
      const formData = new FormData();
      formData.append("images", selectedImage);
      formData.append("Name", productName);
      formData.append("description", description);
      formData.append("category_n", selectedCategory);
      formData.append("sub_category_n", selectedSubCategory);
      formData.append("original_price", originalPrice);
      formData.append("discounted_price", discountedPrice);
      formData.append("quantity", quantity);
      formData.append("is_new", isNew);
      formData.append("is_hot", isHot);
      addProduct(formData)
        .then((res) => {
          productAdd_success();
         
          // clear form
          setProductName("");
          setDescription("");
          setSelectedCategory("");
          setSelectedSubCategory("");
          setOriginalPrice("");
          setDiscountedPrice("");
          setIsNew(false);
          setIsHot(false);
          setQuantity(0);
          setSelectedImage(null);
          setImage(null);
          // Reset file input
          const fileInput = document.getElementById("productImage");
          if (fileInput) {
            fileInput.value = null;
          }
        })
        .catch((err) => {
          productAdd_fail();
        });
    } else {
      product_fill();
    }
  };
  // Add the following function to handle category change
  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    getSubCategories().then((res) => {
      const filterSubCategory = res.filter((subcategory) => {
        return subcategory.category === parseInt(categoryId);
      });

      setSubcategories(filterSubCategory);
    });
  };

  useEffect(() => {
    // fetch categories  and set it to category state
    getCategories().then((res) => {
      setCategory(res);
    });
  }, []);


  return (
    <div className="adminViewProducts">
      <Sidebar />
      <div className="adminListContainer">
        <Navbar />
        <div className="top">
          <h1>Add New Product</h1>
        </div>
        <div className="bottom">
          <div className="left">
            {image ? (
              <img src={image} alt="productImage" />
            ) : (
              <img
                src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                alt="productImage"
              />
            )}

            <div className="file">
              <input
                type="file"
                id="productImage"
                name="filename"
                accept="image/*"
                onChange={handleFileUpload}
              />
            </div>
          </div>
          <div className="right">
            <form>
              <div className="formLeft">
                <label>Product Name</label>
                <input
                  type="text"
                  placeholder="Pressure Cooker Ltr"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
                <label>Product Description</label>
                <input
                  type="text"
                  placeholder="Product Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <label>Quantity</label>
                <input
                  type="text"
                  placeholder="No. of items"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
                <label>Category</label>
                {/* if there is category data render them as option */}
                <select
                  name="category"
                  id="category"
                  defaultValue={"Select an option"}
                  onChange={(e) => handleCategoryChange(e.target.value)}
                >
                  {category.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.Name}
                    </option>
                  ))}
                </select>
                <label>Sub Category</label>
                {/* if there is any subcategory data render them as option */}
                <select
                  name="subcategory"
                  id="subcategory"
                  defaultValue={"Select a subcategory"}
                  onChange={(e) => setSelectedSubCategory(e.target.value)}
                >
                  {subcategories.map((subcategory) => (
                    <option key={subcategory.id} value={subcategory.id}>
                      {subcategory.Name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formRight">
                <div className="formRightTop">
                  <div className="formRightTopLeft">
                    <label>Original Price (in $)</label>
                    <input
                      type="text"
                      placeholder="$1.00"
                      value={originalPrice}
                      onChange={(e) => setOriginalPrice(e.target.value)}
                    />
                  </div>
                  <div className="formRightTopRight">
                    <label>Discounted Price (in $)</label>
                    <input
                      type="text"
                      placeholder="$0.99"
                      value={discountedPrice}
                      onChange={(e) => setDiscountedPrice(e.target.value)}
                    />
                  </div>

                  <div className="formRightBottom">
                    <div className="formRightBottomLeft">
                      <label>New Arrival</label>
                      <select
                        name="isNew"
                        id="isNew"
                        value={isNew ? "yes" : "no"}
                        onChange={(e) => setIsNew(e.target.value === "yes")}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                    <div className="formRightBottomRight">
                      <label>Hot Product</label>
                      <select
                        name="isHot"
                        id="isHot"
                        value={isHot ? "yes" : "no"}
                        onChange={(e) => setIsHot(e.target.value === "yes")}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>
                </div>

                <button id="submit" onClick={handleSubmit}>
                  Add New Product
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default NewProduct;
