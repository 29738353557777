import React, { useState, useEffect } from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import { ReactComponent as IconSearch } from "bootstrap-icons/icons/search.svg";
import Searchlist from "../components/Searchlist";

const Search = () => {
  return (
    <form
      action="/search"
      className="search smallScreenBlock"
      style={{ width: "100%" }}
    >
      <div className="input-group">
        <input
          id="search"
          name="search"
          type="text"
          className="form-control no-outline-focus "
          placeholder="Search for products ..."
          required
          style={{
            padding: "1rem 1.5rem",
            background: "#F3F4F7",
            border: "none",
            borderTopLeftRadius: "15px",
            borderBottomLeftRadius: "15px",
          }}
        />
        <label className="visually-hidden" htmlFor="search"></label>
        <button
          className="btn py-3 no-outline-focus"
          type="submit"
          aria-label="Search"
          style={{
            border: "none",
            padding: "1.5rem",
            background: "#F3F4F7",
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
          }}
        >
          <IconSearch />
        </button>
      </div>
    </form>
  );
};
export default Search;
