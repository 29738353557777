import React, { lazy, useState } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
  useLocation,
} from "react-router-dom";

import Products from "./Admin/AdminProducts/Products";
import NewProduct from "./Admin/AdminProducts/NewProduct";
import ViewProduct from "./Admin/AdminProducts/ViewProduct";
import Users from "./Admin/AdminUsers/Users";
import Orders from "./Admin/AdminOrders/Orders";
const AdminDashboard = lazy(() => import("./Admin/AdminDashboard/Home"));
const AdminLogin = lazy(() => import("./Admin/AdminLogin/Login"));

const NotFoundView = lazy(() => import("./views/pages/404"));

function PrivateRoute(props) {
  const [isAuthenticated, setisAuthenticated] = useState(
    localStorage.getItem("refresh_token")
  );

  const location = useLocation();

  return isAuthenticated ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/admin/login",
        state: { from: location },
      }}
    />
  );
}

export default function AdminLayout(props) {
  return (
    <div>
      <Switch>
        <PrivateRoute
          exact
          path="/admin/dashboard"
          component={AdminDashboard}
        />
        <Route exact path="/admin/login" component={AdminLogin} />
        <PrivateRoute exact path="/admin/orders" component={Orders} />
        <PrivateRoute exact path="/admin/products" component={Products} />
        <PrivateRoute exact path="/admin/products/new" component={NewProduct} />
        <PrivateRoute
          exact
          path="/admin/products/view/:id"
          component={ViewProduct}
        />
        <PrivateRoute exact path="/admin/users" component={Users} />

        <Route component={NotFoundView} />
      </Switch>
    </div>
  );
}
