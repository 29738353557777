import React, {useState, useEffect, lazy} from 'react';
import Fuse from "fuse.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh, faBars } from "@fortawesome/free-solid-svg-icons";
import { Link, Route } from "react-router-dom";
import { withRouter } from "react-router";
import HashLoader from "react-spinners/HashLoader";

const CardProductGrid = lazy(() =>
  import("../components/card/CardProductGrid")
);
const CardProductList = lazy(() =>
  import("../components/card/CardProductList")
);
const CardFeaturedProduct = lazy(() =>
  import("../components/card/CardFeaturedProduct")
);

const Paging = lazy(() => import("./Paging"));


// const override = css`
// display: block;
// margin: auto;
// border-color: red;
// height: 50vh;
// `;



function Searchlist(props) {




    const [products, setproducts] = useState(JSON.parse(localStorage.getItem('data')));
    const [searched, setsearched] = useState([]);
    const [view, setview] = useState('grid');
    const [currentPage, setcurrentPage] = useState(null);
    const [currentProducts, setcurrentProducts] = useState([]);
    const [totalPages, settotalPages] = useState(null);
    const [isSearchcomplete, setisSearchcomplete] = useState(1);
 

    const queryString = require('query-string');

    const parsed = queryString.parse(props.location.search);
    
    const searchinput = parsed['search'];
  
  

// useEffect(() => {
//   setproducts(props.data);

 
// }, [props]);



useEffect(() => {

  
  
    const fuse = new Fuse(products, {
        keys: ["Name", "sub_category"],
        threshold: 0.2,
      });
  
      const result = fuse.search(searchinput);
      const matches = [];
      if (!result.length) {
        setsearched([]);
        
      } else {
        result.forEach(({item}) => {
          matches.push(item);
        });
        setsearched(matches);
      }
      setisSearchcomplete(0);

     
    

    


}, [])



// const onChangeView = (view) => {
//   setview({ view });
//   };


const pageChangeHandler = (page) => {
   
    
 
  const product = searched;
  const { currentPage, totalPages, pageLimit } = page;
  const offset = (currentPage - 1) * pageLimit;
  const currentProducts = product.slice(offset, offset + pageLimit);
  setcurrentPage(currentPage);
  setcurrentProducts(currentProducts);
  settotalPages(totalPages);
 
  
 
};



    
  //   if(products === []){
  // return(<div>
  //   Hello
  // </div>)
  //   } 
  // else{
    if (!products) {
      return (
        <div className='text-center m-auto'>
      <span className='text-center font-weight-bold '>SEARCHING...</span>
 <h4 className="text-center h-75 mt-5 mb-5">
   
          <HashLoader color={"#594f80"} className="animation" size={100} />
        </h4>
        </div>
    
      );
    }

    return (
      <React.Fragment>
        <div className='card 'style = {{width:'auto'}}>

       
        <div className='row'>
         <div className = "m-5 justify-content-center"  >
       
         <span className="text-left font-weight-bold" style={{ textAlign: 'left' }}>
Search result for <strong style={{ color: '#2bbef9', fontWeight:'bold',  textTransform: 'capitalize' , }}>{searchinput}</strong>
</span>
          <div className='row'>
            <div className="col-md-8">
            
              <div className="row">
              
                <div className="col-md-9 ">
                

                  <span className=" font-weight-bold " style={{textAlign: 'left', color: '#2bbef9', fontWeight:'bold' ,}}>
                    {searched.length} items
                    <span className="text-warning"></span>
                  </span>
                 
                </div>
                
               
                <div className="col-md-4">
                
            
                  <div className="btn-group ml-3 float-right" role="group">
                    {/* <button
                      aria-label="Grid"
                      type="button"
                      onClick={() => onChangeView("grid")}
                      title = "grid view"
                      className={`btn ${
                        view === "grid"
                          ? "btn-primary"
                          : "btn-outline-primary"
                      }`}
                    >
                      <FontAwesomeIcon icon={faTh} />
                    </button> */}
                    {/* <button
                      aria-label="List"
                      type="button"
                      title = "list view"
                      onClick={() => onChangeView("list")}
                      className={`btn ${
                        view === "list"
                          ? "btn-primary"
                          : "btn-outline-primary"
                      }`}
                    >
                      <FontAwesomeIcon icon={faBars} />
                    </button> */}
                 
                 
                  </div>
                 
                </div>
                
              </div>
              
              <hr />
             
              <div className="row g-3">
             
           
                {
                  currentProducts.map((product, idx) => {
                    return (
                      <div key={idx} className="col-md-3 ">
                        <CardProductGrid data={product} />
                        
                      </div>
                        
                      
                    );
                  })
                  }
                    
                
                   
                 
                {/* {view === "list" &&
                  currentProducts.map((product, idx) => {
                    return (
                      <div key={idx} className="col-md-10 mx-5">
                        <CardProductList data={product} />
                      </div>
                    );
                  })} */}
              
              
              </div>
             
              <hr />
              
          
              <Paging
                totalRecords={searched.length}
                pageLimit={12}
                pageNeighbours={3}
                onPageChanged={pageChangeHandler}
                sizing=""
                alignment="justify-content-center"
              /> 
              
            </div>
            {(searched.length>1) && (<div className="col-md-3 mt-5 mx-auto ">
                        <CardFeaturedProduct product={searched[1]} />
                      </div> )}
            </div>
           
          
            </div>
           
            </div>
            </div>
            
            
            </React.Fragment>
       
    )

    

  };


export default withRouter(Searchlist);
