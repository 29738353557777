import axios from "axios";

export const getAllProducts = async () => {
  const res = await axios.get(`${process.env.REACT_APP_BASE_URL}`);
  if (res) {
    return res.data;
  }
};

// export const fetchProductItem = async (id) => {
//   const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${id}`);

//   if (response.ok) {
//     const product = await response.json();
//     console.log("product", product);
//     return product;
//   }
// };
export const fetchProductItem = async (id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/${id}`);

    if (response.status === 200) {
      const product = response.data;

      return product;
    }
  } catch (error) {
    console.error("Error fetching product item:", error);
  }
};

export const addProduct = async (data) => {
  try {
    const config = {
      headers: {
        Authorization: localStorage.getItem("access_token")
          ? "Bearer " + localStorage.getItem("access_token")
          : null,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };

    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/adminuser/add_product/`,
      data,
      config
    );
    if (res.status === 200) {
      return res;
    }
  } catch (err) {
    return err;
  }
};

// working
export const getCategories = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/categories/`
    );
    if (res) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

// working
export const getSubCategories = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/subcategories/`
    );
    if (res) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

// users
export const getAllUsers = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/adminuser/user_detail/`,
    {
      headers: {
        Authorization: localStorage.getItem("access_token")
          ? "Bearer " + localStorage.getItem("access_token")
          : null,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }
  );
  if (res.status === 200) {
    return res.data;
  }
};

export const deleteProduct = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/adminuser/delete_product/`,
      {
        headers: {
          Authorization: localStorage.getItem("access_token")
            ? "Bearer " + localStorage.getItem("access_token")
            : null,
          "Content-Type": "application/json",
          accept: "application/json",
        },
        data: {
          id,
        },
      }
    );
    if (res) {
      return res;
    }
  } catch (err) {
    return err;
  }
};

export const updateProduct = async (data) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/adminuser/update_product/`,
      data,
      {
        headers: {
          Authorization: localStorage.getItem("access_token")
            ? "Bearer " + localStorage.getItem("access_token")
            : null,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    if (res.status === 200) {
      console.log("product updated", res);
      return res;
    }
  } catch (err) {
    return err;
  }
};

export const getOrders = async () => {
  //https://ecommerce-vex.herokuapp.com/adminuser/oorders/
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/adminuser/oorders/`
    );
    if (res) {
      console.log("orders", res);
      return res.data;
    }
  } catch (err) {
    return err;
  }
};
