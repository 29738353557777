import React, { useState, useEffect } from "react";
import Sidebar from "../adminComponents/sidebar/Sidebar";
import Navbar from "../adminComponents/navbar/Navbar";
import "./users.scss";
import { CheckCircle, Cancel } from "@mui/icons-material";
import Datatable from "../adminComponents/datatable/Datatable";
import { getAllUsers } from "../../actions/adminRequests";

const userColumns = [
  { field: "id", headerName: "ID" },

  {
    field: "email",
    headerName: "email",
    width: 200,
  },
  {
    field: "Full Name",
    headerName: "Full Name",
    width: 100,
    renderCell: (params) => {
      return <div>{params.row.first_name + " " + params.row.last_name}</div>;
    },
  },
  {
    field: "mobile_phone",
    headerName: "Phone",
  },
  {
    field: "city",
    headerName: "Address",
  },
  {
    field: "is_active",
    headerName: "Status",

    renderCell: (params) => {
      const isNew = params.value;

      if (isNew) {
        return <CheckCircle style={{ color: "green" }} />;
      } else {
        return <Cancel style={{ color: "red" }} />;
      }
    },
  },
];

function Users() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getAllUsers().then((res) => {
      setUsers(res);
    });
  }, []);

  return (
    <div className="adminUsers">
      <Sidebar />
      <div className="adminListContainer">
        <Navbar />
        {/* <Datatable rows={rows} columns={columns} state={state}/> */}
        <div>
          <Datatable rows={users} columns={userColumns} state="users" />
        </div>
      </div>
    </div>
  );
}

export default Users;
