import React, { Component, useState } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
function ProtectedRoute(props) {
  const [isAuthenticated, setisAuthenticated] = useState(
    localStorage.getItem("refresh_token")
  );

  const location = useLocation();

  return isAuthenticated ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/account/signin",
        state: { from: location },
      }}
    />
  );
}

export default ProtectedRoute;
