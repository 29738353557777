import React from "react";
import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import HashLoader from "react-spinners/HashLoader";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { deleteProduct } from "../../../actions/adminRequests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Datatable = ({ rows, columns, state }) => {
  const [data, setData] = useState([]);

  const deleteSuccess = () => {
    toast.success("Product Deleted Successfully", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
    });
  };

  const deleteError = () => {
    toast.error("Something went wrong", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
    });
  };

  const handleDelete = (id) => {
    deleteProduct(id)
      .then((res) => {
        deleteSuccess();
      })
      .catch((err) => {
        deleteError();
      });
    setData(data.filter((item) => item.id !== id));
  };
  const [isLoading, setIsLoading] = React.useState(false);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/admin/${state}/view/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  // Conditionally add the action column based on the state
  const modifiedColumns =
    state !== "users" ? columns?.concat(actionColumn) : columns;

  // Check if the data array is empty

  useEffect(() => {
    if (rows.length === 0) {
      setIsLoading(true);
    } else {
      setData(rows);
      setIsLoading(false);
    }
  }, [rows]);

  return (
    <div className="datatable">
      {isLoading ? (
        <div className="loading-container">
          <HashLoader color={"#594f80"} className="animation" size={100} />
        </div>
      ) : (
        <>
          <div className="datatableTitle">
            {state === "products" ? (
              <Link to={`/admin/${state}/new`} className="link">
                Add New Product
              </Link>
            ) : (
              <></>
            )}
          </div>
          <DataGrid
            className="datagrid"
            rows={data}
            // columns={columns?.concat(actionColumn)}
            columns={modifiedColumns}
            pageSize={9}
            rowsPerPageOptions={[10]}
            checkboxSelection
          />
          <ToastContainer />
        </>
      )}
    </div>
  );
};

export default Datatable;
