import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Search from "../components/Searchbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as IconPerson } from "bootstrap-icons/icons/person.svg";
import { ReactComponent as IconCart } from "bootstrap-icons/icons/bag.svg";
import TopMenu from "./TopMenu";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { logout } from "../actions/requests";
import api from "../actions/axiosInstance";
import MobileHeader from "./MobileHeader";

const TopHeader = ({ isAuthenticated }) => {
  return (
    <React.Fragment>
      <div className="smallScreenBlock d-md-block col-md-10 mt-2 offset-md-1">
        <div className="row">
          <div className="text-center d-flex justify-content-between">
            <div>
              <small>
                <Link to="/about" className="topheader-links">
                  About Us
                </Link>
                <span className="mx-2">|</span>
                <Link to="/account/orders" className="topheader-links">
                  Order Tracking
                </Link>

                <span className="mx-2">|</span>
                <Link to="/contact-us" className="topheader-links">
                  {" "}
                  Contact Us
                </Link>
                <span className="mx-2">|</span>
                {isAuthenticated ? (
                  <span
                    onClick={logout}
                    className="topheader-links"
                    style={{ cursor: "pointer" }}>
                    Logout
                  </span>
                ) : (
                  <Link to="/account/signin" className="topheader-links">
                    Login
                  </Link>
                )}
              </small>
            </div>

            <div>
              <small>
                <strong>100% secure delivery</strong> on all orders
              </small>
            </div>

            <div>
              <small>Need Help? Call Us Anytime. </small>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const Header = () => {
  const [isAuthenticated, setisAuthenticated] = useState(
    localStorage.getItem("refresh_token") ? true : false
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [cartDataLength, setCartDataLength] = useState(0);
  useEffect(() => {
    if (isAuthenticated) {
      // check the authentication
      api
        .get("/account/detail/")
        .then((res) => {
          console.log("user detail request", res.data);
          setFirstName(res.data.first_name);
          setLastName(res.data.last_name);
          localStorage.setItem("user_name", firstName + lastName);
          localStorage.setItem("user_id", res.data.id);
        })
        // .then(() => console.log("request to bazar done"))
        .catch((err) => console.log(err));
      // calculate the cart item
    }
  }, [firstName, isAuthenticated, lastName]);

  return (
    <React.Fragment>
      <header className="sticky-top  border-bottom text-center  bg-light ">
        <TopHeader isAuthenticated={isAuthenticated} />
        <hr className="smallScreenBlock" />
        <div className="container-fluid col-md-10 offset-md-1">
          <div className="row g-3 text-center">
            <div
              className="col-sm-4 col-md-4"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
              }}>
              <Link to="/" className=" text-center ">
                <img
                  alt="logo"
                  src="../../images/logo.png"
                  style={{ width: "150px", height: "110px" }}
                />
              </Link>
              <div className=" d-md-block">
                <button
                  type="submit"
                  className="smallScreenBlock btn text-white btn-block"
                  style={{
                    padding: "15px 20px",
                    borderRadius: "50px",
                    fontSize: "bold",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f4bb44",
                  }}>
                  <FontAwesomeIcon
                    icon={faPhoneVolume}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                  />
                  01707-123456
                </button>
              </div>
            </div>

            <div className="col-md-4 d-flex align-items-center ">
              <Search />
            </div>

            <div className="col-sm-4 col-md-4 d-flex">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: "100%" }}>
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    border: "1px solid #E2E4EC",
                    marginRight: "20px",
                  }}>
                  <Link
                    to={
                      isAuthenticated ? "/account/profile" : "/account/signin"
                    }
                    className="text-decoration-none text-dark">
                    {isAuthenticated ? (
                      <div
                        style={{
                          backgroundColor: "red",
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                        }}>
                        <h5 className="text-white" style={{ padding: "8px" }}>
                          {firstName[0] + lastName[0]}
                        </h5>
                      </div>
                    ) : (
                      <div>
                        <IconPerson style={{ width: "25px", height: "35px" }} />
                      </div>
                    )}
                  </Link>
                </div>
                <div
                  style={{
                    marginRight: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <strong style={{ color: "#202435" }}>$ 0.00</strong>
                </div>

                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      width: "40px",
                      height: "40px",
                      background: "#FFF1EE",
                      border: "1px solid #FFF1EE",
                      borderRadius: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Link to={isAuthenticated ? "/cart" : "/account/signin"}>
                      <IconCart
                        style={{
                          width: "15px",
                          height: "15px",
                          color: "red",
                        }}
                      />
                    </Link>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "-5px",
                      right: "-5px",
                      backgroundColor: "red",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <strong style={{ color: "white" }}>{cartDataLength}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TopMenu />
      </header>
    </React.Fragment>
  );
};

export default Header;
