import React from "react";
import Sidebar from "../adminComponents/sidebar/Sidebar";
import Navbar from "../adminComponents/navbar/Navbar";
import "./orders.scss";
function Orders() {
  return (
    <div className="adminOrders">
      <Sidebar />
      <div className="adminListContainer">
        <Navbar />
        {/* process of making new edit */}
        {/* <Datatable rows={rows} columns={columns} state={state}/> */}
        <div>Orders</div>
      </div>
    </div>
  );
}

export default Orders;
