import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faPalette,
  faNotesMedical,
} from "@fortawesome/free-solid-svg-icons";

export default function TopMenu() {
  return (
    <div className="container-fluid bg-light col-md-10 offset-md-1">
      <div className="row">
        <nav className="col-12 col-md-12 navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse " id="navbarNav">
              <ul className="navbar-nav ">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    <span className="nav-link-items">Home</span>
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <p
                    className="nav-link dropdown-toggle"
                    id="foodDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {/* <FontAwesomeIcon icon={faPlateUtensils} /> */}
                    <span className="nav-link-items">Food</span>

                    <FontAwesomeIcon icon={faChevronDown} />
                  </p>
                  <ul className="dropdown-menu" aria-labelledby="foodDropdown">
                    <li>
                      <a className="dropdown-item" href="/category/achaar">
                        Achaar
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/category/spices">
                        Spices
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/category/snacks-and-noodles"
                      >
                        Snacks and Noodles
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/category/titaura">
                        Titaura
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/category/dal-and-beans"
                      >
                        Dal and Beans
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/category/native-foods"
                      >
                        Native Foods
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/category/tea">
                        Tea
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="nav-item dropdown">
                  <p
                    className="nav-link dropdown-toggle"
                    id="fashionDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {/* <FontAwesomeIcon icon={faShirt} /> */}
                    <span className="nav-link-items">Fashion</span>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </p>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="fashionDropdown"
                  >
                    <li>
                      <a className="dropdown-item" href="/category/dhaka">
                        Dhaka
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/category/pashmina">
                        Pashmina
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/category/accessories">
                        Accessories
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <p
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="handicraftsDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon icon={faPalette} />
                    <span className="nav-link-items">Handicrafts</span>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </p>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="handicraftsDropdown"
                  >
                    <li>
                      <a className="dropdown-item" href="/category/sculptures">
                        Sculptures
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/category/paintings">
                        Paintings
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/category/weddings-accessories"
                      >
                        Wedding Accessories
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/category/pooja-items">
                    {/* <FontAwesomeIcon icon={faFlower} /> */}
                    <span className="nav-link-items">Pooja Items</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/category/health-and-beauties">
                    <FontAwesomeIcon icon={faNotesMedical} />
                    <span className="nav-link-items"> Health and Beauties</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/category/kitchen-items">
                    {/* <FontAwesomeIcon icon={faKnifeKitchen} /> */}
                    <span className="nav-link-items"> Kitchen Items</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
