import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container">
          <div className="row ">
            <div className="col-md-8">
              <div>
                <div className="col-md-4">
                  <Link to="/" className="text-center">
                    <img
                      alt="logo"
                      src="../../images/eghumti_white.png"
                      style={{ width: "150px", height: "100px" }}
                    />
                  </Link>
                </div>
                <div className="col-md-8 text-white">
                  <p>Texas</p>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div style={{ textAlign: "left", fontSize: "14px" }}>
                <h4 className="font-bold text-white ">Pages</h4>
                <ul className="footer-links ">
                  <li>
                    <Link
                      to="/"
                      className="text-white"
                      style={{ textDecoration: "none" }}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      className="text-white"
                      style={{ textDecoration: "none" }}
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact-us"
                      className="text-white"
                      style={{ textDecoration: "none" }}
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/faq"
                      className="text-white"
                      style={{ textDecoration: "none" }}
                    >
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/terms"
                      className="text-white"
                      style={{ textDecoration: "none" }}
                    >
                      Terms & Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2">
              <div style={{ textAlign: "left", fontSize: "14px" }}>
                <h4 className="font-bold text-white">Socials</h4>
                <ul className="footer-links">
                  <li>
                    <a
                      href="https://www.facebook.com"
                      className="text-white"
                      style={{ textDecoration: "none" }}
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:info@eghumti.com"
                      className="text-white"
                      style={{ textDecoration: "none" }}
                    >
                      Email
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-md-6">
              <div className="text-center text-white">
                <span>© eGhumti Ecommerce </span>
                <span>All rights reserved.</span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
