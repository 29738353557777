import React, { useState, useEffect } from "react";
import Sidebar from "../adminComponents/sidebar/Sidebar";
import Navbar from "../adminComponents/navbar/Navbar";
import "./products.scss";
import Datatable from "../adminComponents/datatable/Datatable";

import { CheckCircle, Cancel } from "@mui/icons-material";

import { getAllProducts } from "../../actions/adminRequests";

const productColumns = [
  { field: "id", headerName: "ID", width: 70 },

  {
    field: "Name",
    headerName: "Product Name",
    width: 230,
  },

  {
    field: "description",
    headerName: "Product Description",
    width: 230,
  },
  {
    field: "category_n",
    headerName: "Category",
    renderCell: (params) => {
      const categoryNumber = params.value;
      if (categoryNumber === 1) {
        return "food";
      } else if (categoryNumber === 2) {
        return "health-and-beauty";
      } else if (categoryNumber === 3) {
        return "fashion";
      } else if (categoryNumber === 4) {
        return "handicrafts";
      } else if (categoryNumber === 5) {
        return "pooja-items";
      } else if (categoryNumber === 6) {
        return "kitchen-items";
      }
    },
  },
  {
    field: "original_price",
    headerName: "Original Price",
  },
  {
    field: "discounted_price",
    headerName: "Discounted Price",
  },
  {
    field: "is_new",
    headerName: "New",

    renderCell: (params) => {
      const isNew = params.value;

      if (isNew) {
        return <CheckCircle style={{ color: "green" }} />;
      } else {
        return <Cancel style={{ color: "red" }} />;
      }
    },
  },
  {
    field: "is_hot",
    headerName: "Hot",

    renderCell: (params) => {
      const isNew = params.value;

      if (isNew) {
        return <CheckCircle style={{ color: "green" }} />;
      } else {
        return <Cancel style={{ color: "red" }} />;
      }
    },
  },
];

function Products() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getAllProducts().then((res) => {
      setProducts(res);
    });
  }, []);

  return (
    <div className="adminProducts">
      <Sidebar />
      <div className="adminListContainer">
        <Navbar />
        {/* <Datatable rows={rows} columns={columns} state={state}/> */}
        <div>
          <Datatable
            rows={products}
            columns={productColumns}
            state="products"
          />
        </div>
      </div>
    </div>
  );
}

export default Products;
