import React from "react";
import TopMenu from "./TopMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as IconCart } from "bootstrap-icons/icons/bag.svg";
import { ReactComponent as IconPerson } from "bootstrap-icons/icons/person.svg";

function MobileHeader({
  isAuthenticated,
  firstName,
  lastName,
  cartDataLength,
}) {
  return (
    <div className="mobile-navbar">
      {/* navbar */}
      {/* <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button> */}
      {/* logo and number */}
      <div className="row g-3 text-center">
        <div
          className="col-sm-4 col-md-4"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Link to="/" className=" text-center ">
            <img
              alt="logo"
              src="../../images/logo.png"
              style={{ width: "150px", height: "100px" }}
            />
          </Link>
          <div className=" d-md-block">
            <button
              type="submit"
              className="smallScreenBlock btn text-white btn-block"
              style={{
                padding: "15px 20px",
                borderRadius: "50px",
                fontSize: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#f4bb44",
              }}
            >
              <FontAwesomeIcon
                icon={faPhoneVolume}
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "5px",
                }}
              />
              01707-123456
            </button>
          </div>
        </div>
      </div>
      {/*profile and cart icon only */}
      <div className="profile-cart-mobile">
        <div
          className="profile"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border: "1px solid #E2E4EC",
            marginRight: "20px",
          }}
        >
          <Link
            to={isAuthenticated ? "/account/profile" : "/account/signin"}
            className="text-decoration-none text-dark"
          >
            {isAuthenticated ? (
              <div
                style={{
                  backgroundColor: "red",
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                }}
              >
                <h5 className="text-white" style={{ padding: "8px" }}>
                  {firstName[0] + lastName[0]}
                </h5>
              </div>
            ) : (
              <div>
                <IconPerson style={{ width: "25px", height: "35px" }} />
              </div>
            )}
          </Link>
        </div>
        <div className="cart" style={{ position: "relative" }}>
          <div
            style={{
              width: "40px",
              height: "40px",
              background: "#FFF1EE",
              border: "1px solid #FFF1EE",
              borderRadius: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link to={isAuthenticated ? "/cart" : "/account/signin"}>
              <IconCart
                style={{
                  width: "15px",
                  height: "15px",
                  color: "red",
                }}
              />
            </Link>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-5px",
              right: "-5px",
              backgroundColor: "red",
              borderRadius: "50%",
              width: "20px",
              height: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <strong style={{ color: "white" }}>{cartDataLength}</strong>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileHeader;
