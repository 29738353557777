import React, { Suspense, lazy, useEffect, useState } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.min.css";
import ProtectedRoute from "./ProtectedRoute";
const HomeView = lazy(() => import("./views/Home"));
const SignInView = lazy(() => import("./views/account/SignIn"));
const SignUpView = lazy(() => import("./views/account/SignUp"));
const Confirmation = lazy(() => import("./views/account/Confirmation"));
const ForgotPasswordView = lazy(() => import("./views/account/ForgotPassword"));
const OrdersView = lazy(() => import("./views/account/Orders"));
const NotificationView = lazy(() => import("./views/account/Notification"));
const MyProfileView = lazy(() => import("./views/account/Profile"));
const ResetPassword = lazy(() => import("./views/account/ResetPassword"));
const ProductListView = lazy(() => import("./views/product/List"));
const ProductDetailView = lazy(() => import("./views/product/Detail"));
const StarZoneView = lazy(() => import("./views/product/StarZone"));
const CartView = lazy(() => import("./views/cart/Cart"));
const CheckoutView = lazy(() => import("./views/cart/Checkout"));
const DocumentationView = lazy(() => import("./views/Documentation"));
const NotFoundView = lazy(() => import("./views/pages/404"));
const InternalServerErrorView = lazy(() => import("./views/pages/500"));
const ContactUsView = lazy(() => import("./views/pages/ContactUs"));
const SupportView = lazy(() => import("./views/pages/Support"));
const BlogView = lazy(() => import("./views/blog/Blog"));
const BlogDetailView = lazy(() => import("./views/blog/Detail"));
const Search = lazy(() => import("./components/Searchlist"));
const OTPVerification = lazy(() => import("./views/account/OTPVerification"));
export default function PublicLayout() {
  const [data, setdata] = useState([]);

  useEffect(() => {
    const baseurl = process.env.REACT_APP_BASE_URL;
    const axios = require("axios").default;

    if (!localStorage.getItem("data")) {
      axios
        .get(baseurl)
        .then((res) => {
          localStorage.setItem("data", JSON.stringify(res.data));
          setdata(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      setdata(JSON.parse(localStorage.getItem("data")));
    }
  }, []);
  return (
    <div>
      <Header />

     
        <Switch>
          <Route exact path="/" component={HomeView} />

          <Route exact path="/account/signin" component={SignInView} />
          <Route exact path="/account/signup" component={SignUpView} />
          <Route exact path="/account/confirmation" component={Confirmation} />
          <Route
            exact
            path="/account/forgotpassword"
            component={ForgotPasswordView}
          />
          <Route
            exact
            path="/account/otpverification"
            component={OTPVerification}
          />
          <ProtectedRoute
            exact
            path="/account/profile"
            component={MyProfileView}
          />
          <Route
            exact
            path="/account/resetpassword"
            component={ResetPassword}
          />
          <Route exact path="/account/orders" component={OrdersView} />
          <Route
            exact
            path="/account/notification"
            component={NotificationView}
          />
          <Route
            path="/category/:cat_name"
            component={(props) => (
              <ProductListView {...props} key={window.location.pathname} />
            )}
          />
          <Route
            exact
            path="/product/detail/:id"
            component={(props) => (
              <ProductDetailView {...props} key={window.location.pathname} />
            )}
          />
          <Route exact path="/star/zone" component={StarZoneView} />
          <Route
            exact
            path="/search"
            component={(props) => <Search {...props} data={data} />}
          />
          <ProtectedRoute exact path="/cart" component={CartView} />
          <ProtectedRoute exact path="/checkout" component={CheckoutView} />
          <Route exact path="/documentation" component={DocumentationView} />
          <Route exact path="/contact-us" component={ContactUsView} />
          <Route exact path="/support" component={SupportView} />
          <Route exact path="/blog" component={BlogView} />
          <Route exact path="/blog/detail" component={BlogDetailView} />
          <Route exact path="/500" component={InternalServerErrorView} />

          <Route component={NotFoundView} />
        </Switch>
      
      <Footer />
    </div>
  );
}
