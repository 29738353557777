import axios from "axios";
//const baseurl = process.env.REACT_APP_BASE_URL;

export const login = async (email, password, redirectTo) => {
  try {
    const body = {
      username: email,
      password: password,
      grant_type: "password",
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/account/auth/token/`,
      body
    );

    if (res) {
      localStorage.setItem("access_token", res.data.access_token);
      localStorage.setItem("refresh_token", res.data.refresh_token);
      setTimeout(() => {
        window.location.href = redirectTo ? redirectTo : "/";
      }, 3000);
      return res;
    }
  } catch (res) {
    console.log(res);
    return res.response.data;
  }
};

export const logout = async () => {
  window.localStorage.removeItem("access_token");
  window.localStorage.removeItem("refresh_token");
  window.localStorage.removeItem("user_id");
  window.localStorage.removeItem("user_update_id");
  window.location.href = "/account/signin";
};

export const register = async (
  firstName,
  middleName,
  lastName,
  mobileNo,
  email,
  password,
  confirm_pwd
) => {
  // try {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    firstName,
    middleName,
    lastName,
    mobileNo,
    email,
    password,
    confirm_pwd,
  });

  const res = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/account/registration/`,
    body,
    config
  );

  return res;

  // } catch (err) {
  //   // console.log("Error in cathc", err.response);
  //   return err.response;
  // }
};

export const signup_otp_generate = (email) => {
  try {
    const res = axios.get(
      `${process.env.REACT_APP_BASE_URL}/account/otp_gen_verify/${email}/`
    );
    if (res) {
      return res;
    }
  } catch (err) {
    console.log("Error from otp generate action", err);
  }
};

export const signup_otp_verify = async (otp, email) => {
  const body = {
    OTP: otp,
  };

  const res = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/account/otp_gen_verify/${email}/`,
    body
  );
  return res;
};

export const googleRegister = async (response) => {
  const body = {
    token: response,
    backend: "google-oauth2",
    grant_type: "convert_token",
    client_id: process.env.REACT_APP_CLIENT_GOOGLE_ID,
    client_secret: process.env.REACT_APP_CLIENT_GOOGLE_SECRET,
  };

  await axios
    .post(`${process.env.REACT_APP_BASE_URL}/account/auth/convert-token/`, body)
    .then((res) => {
      localStorage.setItem("access_token", res.data.access_token);
      localStorage.setItem("refresh_token", res.data.refresh_token);
      window.location.href = "/";
      return res;
    })
    .catch((err) => {
      console.log("Error in Google signup", err.response.data);
      return err;
    });
};

export const otpgenerate = (email) => {
  try {
    const res = axios.get(
      `${process.env.REACT_APP_BASE_URL}/account/reset_password_email/${email}/`
    );
    if (res) {
      return res;
    }
  } catch (err) {
    console.log("Error from otp generate action", err);
  }
};

export const otpverify = async (otp, email) => {
  const body = {
    OTP: otp,
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/account/reset_password_email/${email}/`,
      body
    );
    if (res) {
      console.log("otp verify", res);
      return res;
    }
  } catch (err) {
    return err;
    // console.log("Error from otp verify action", err.response);
  }
};

export const resetpassword = async (otp, password, email) => {
  const body = {
    OTP: otp,
    password: password,
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/account/reset_password_email/${email}/`,
      body
    );
    if (res) {
      console.log("password reset", res);
      return res;
    }
  } catch (err) {
    return err;
    // console.log("Error from otp verify action", err.response);
  }
};

export const forgotpassword = async (formData) => {
  try {
    const config = {
      headers: {
        Authorization: localStorage.getItem("access_token")
          ? "Bearer " + localStorage.getItem("access_token")
          : null,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };

    const res = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/account/change-password/`,
      formData,
      config
    );
    if (res) {
      console.log("forgot password", res.data);
      return res.data;
    }
  } catch (err) {
    console.log("Error from forgot password action", err);
    return err.old_password;
  }
};
